import React, { useState, useEffect } from 'react';
import api from '../../middleware/api';
import { useHistory, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2';
import Sidebar from "../../common/Sidebar"
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
const AddCategoryContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginTop: '20px',
  marginLeft: '20px',
  width: '100%'

});

const AddCategoryTitle = styled(Typography)(({ theme }) => ({
  marginTop: '64px',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  }
}));

const AddCategoryForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '50%'
});

const PhotoTableContainer = styled(TableContainer)({
  marginTop: '20px',
});

export const EditCategory = () => {
  const { id } = useParams();
  const history = useHistory();
  const [category, setCategory] = useState({
    category_code: '',
    category_name: '',
    description: ''
  }); 

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setCategory(prevCategory => ({
      ...prevCategory,
      [name]: inputValue,
    }));
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (id) {
      api.get(`https://api.holvend.com/api/category/${id}`)
        .then(response => { 
          const { category_code, category_name, description } = response.data.payload.datas;
          setCategory({
            category_code,
            category_name,
            description
          });
         
        })
        .catch(error => {
        });
    }
  }, []);

 

  const showPopup = (message, type) => {
    return Swal.fire({
      title: message,
      icon: type,
      confirmButtonText: 'OK',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    var data = {
      'category_code': category.category_code,
      'category_name': category.category_name,
      'description': category.description,
    };
  
    // Handle add product logic
    api.patch('https://api.holvend.com/api/category', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      showPopup('Product Edit', 'success').then(() => {
        history.push('/categorypaging'); // Redirect to home page after successful edit
      });
    }).catch(error => {
      if (error.response && error.response.status === 409) {
        showPopup('Category Code Not Unique', 'error');
      } else {
        console.error(error);
      }
    });
  };
  
 
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <AddCategoryContainer>
        <AddCategoryTitle variant="h4" component="h4">
          Edit Category
        </AddCategoryTitle>
        <AddCategoryForm onSubmit={handleSubmit}>
          <TextField
            label="Category Code"
            name="category_code"
            value={category.category_code}
            onChange={handleChange}
            disabled={id}
            required
          />
          <TextField
            label="Category Name"
            name="category_name"
            value={category.category_name}
            onChange={handleChange}
            required
          />
                   <FormControl>
            <FormLabel>Description</FormLabel>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter description"
              name="description"
              value={category.description}
              onChange={handleChange}
              style={{ width: '100%', resize: 'vertical' }}
              required
            />
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            Save Category
          </Button>
        </AddCategoryForm>
      </AddCategoryContainer>
    </Box>
  );
};

export default EditCategory;
