import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import holvend from "../data/logoholvend160.png"
import axios from "axios";

export const Header = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isShopHovered, setIsShopHovered] = useState(false);
  const [isCategoryShown, setIsCategoryShown] = useState(false);
  const [categories, setCategory] = useState([]);
  const handleMenuClick = () => {
    setIsMenuOpened(!isMenuOpened);
    setIsShopHovered(false);
    setIsCategoryShown(false);
  };

  const handleShopMouseEnter = () => {
    setIsShopHovered(true);
  };

  const handleShopMouseLeave = () => {
    setIsShopHovered(false);
  };

  const navlink = [
    {
      url: "/home",
      text: "HOME",
    },
    {
      url: "/shop",
      text: "SHOP",
    },
    {
      url: "/contact",
      text: "CONTACT",
    },
    {
      url: "/about",
      text: "ABOUT",
    },
  ];

  const categoryShop = [
    {
      url: "/shop/Pomades",
      category: 'Pomades',
      text: "HAIR STYLING",
    },
    {
      url: "/shop/HairProducts",
      category: 'HairProducts',
      text: "HAIR CARE",
    },
    {
      url: "/shop/MensBeardShave",
      category: 'MensBeardShave',
      text: "SHAVES",
    },
    {
      url: "/shop/",
      category: 'Merchandise',
      text: "APPAREL & MERCH",
    },
  ];

  const showCategory = () => {
    setIsCategoryShown(!isCategoryShown);
  }

  const getCategory = async () => {
    const url = "https://api.holvend.com/api/category";
    try {
      const response = await axios.get(url);
      setCategory(response.data.payload.datas);
    } catch (error) {
      console.error('Error fetching product:', error);
    }

  }
  useEffect(() => {
    getCategory();
  }, [])
  return (
    <>
      <header>
        <a href="#" className={isMenuOpened ? 'page-shade' : 'hideShade'} onClick={handleMenuClick} />
        <div className='container flexsb'>
          <a href="https://holvend.com">
            <img className='logo-style' src={holvend} alt='' data-aos="zoom-in" />
          </a>

          {/* <div className={isMenuOpened ? "showMenu" : "hideMenu"}>
            <button
              className={`menu ${isMenuOpened ? 'opened' : ''}`}
              onClick={handleMenuClick}
              aria-label="Main Menu">
              <svg width="50" height="50" viewBox="0 0 100 100">
                <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path className="line line2" d="M 20,50 H 80" />
                <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
              </svg>
            </button>
            {navlink.map((links, i) => (
              <Link to={links.url} key={i} data-aos='zoom-in-left' onClick={handleMenuClick}>
                {links.text}
              </Link>
            ))}
          </div> */}

          <div className="sidebar-overlay">
            <div className={`mobileMenu${isMenuOpened ? '' : ' hideMenu'}`}>
              <div className={`headMobileMenu`}>
                <button
                  className={`backButton${isCategoryShown ? '' : ' hideMenu'}`}
                  onClick={showCategory}>
                  <svg className="chevron-icon icon-left" viewBox="0 0 320 512" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path></svg>
                  <span className="back-menu" >SHOP</span>
                </button>
                <button
                  className="opened"
                  onClick={handleMenuClick}>
                  <svg width="50" height="50" viewBox="0 0 100 100">
                    <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                    <path className="line line2" d="M 20,50 H 80" />
                    <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                  </svg>
                </button>
              </div>

              <div className={`mobileMenu-content sidebar-category  ${isCategoryShown ? '' : 'hideMenu'}`}>
                {categories.map((links, i) => (
                  <Link to={`/shop/${links.category_code}`} key={i} onClick={handleMenuClick}>
                    {links.category_name}
                  </Link>
                ))}
              </div>
              <div className="mobileMenu-content" data-aos='zoom-in-left'>
                {navlink.map((links, i) => (
                  links.text === "SHOP" ? (
                    <div key={i} className="categoryMenu">
                      <Link to={links.url} key={i} onClick={handleMenuClick}>
                        {links.text}
                      </Link>
                      <button
                        onClick={showCategory}>
                        <svg className="chevron-icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                          <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <Link to={links.url} key={i} onClick={handleMenuClick}>
                      {links.text}
                    </Link>
                  )
                ))}
              </div>

            </div>

          </div>

          <div className="desktopMenu">
            {navlink.map((links, i) => (
              <div key={i} data-aos='zoom-in-left'>
                {links.text === "SHOP" ? (
                  <div
                    onMouseEnter={handleShopMouseEnter}
                    onMouseLeave={handleShopMouseLeave}
                  >
                    <Link to={links.url}>
                      {links.text}
                    </Link>

                    {isShopHovered && (
                      <div className="dropdown-menu">
                        {categories.map((links, i) => (
                          <Link to={`/shop/${links.category_code}`} key={i} onClick={handleMenuClick}>
                            {links.category_name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link to={links.url}>
                    {links.text}
                  </Link>
                )}
              </div>
            ))}
          </div>

          <button
            className={`menu ${isMenuOpened ? '' : ''}`}
            onClick={handleMenuClick}
            aria-label="Main Menu">
            <svg width="50" height="50" viewBox="0 0 100 100">
              <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
              <path className="line line2" d="M 20,50 H 80" />
              <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
            </svg>
          </button>

        </div>
      </header>
    </>
  )
}
