import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../middleware/api';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2';
import Sidebar from "../../common/Sidebar"
import FormControlLabel from '@mui/material/FormControlLabel';

const AddSliderPhotoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '20px',
  marginLeft: '20px',

});

const AddSliderPhotoTitle = styled(Typography)(({ theme }) => ({
  marginTop: '64px',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const AddSliderPhotoForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',

});

const SliderPhotoTableContainer = styled(TableContainer)({
  marginTop: '20px',
});

export const EditSliderPhoto = () => {
  const { id } = useParams();
  const history = useHistory();
  const [sliderPhoto, setSliderPhoto] = useState({
    order: '',
    is_active: true
  });
   

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setSliderPhoto(prevProduct => ({
      ...prevProduct,
      [name]: inputValue,
    }));
  };
 

  const showPopup = (message, type) => {
    return Swal.fire({
      title: message,
      icon: type,
      confirmButtonText: 'OK',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var data = {
      'id': id,
      'order': sliderPhoto.order,
      'is_active': sliderPhoto.is_active,
    };
    
    api.patch('https://api.holvend.com/api/sliderphoto', data, {
      headers: {
        'Content-Type': 'application/json', // Menggunakan header JSON
      },
    }).then(response => {
      showPopup('Slider Photo Edited', 'success').then(() => {
        history.push('/sliderphotopaging'); // Redirect to home page after successful add
      });
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        showPopup('Photo Required', 'error');
      } else {
        console.error(error);
      }
    });
    
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (id) {
      api.get(`https://api.holvend.com/api/sliderphoto/${id}`)
        .then(response => {
          const { order, is_active } = response.data.payload.datas;
          setSliderPhoto({
            order,
            is_active
          });
        
        })
        .catch(error => {
        });
    }
  }, []);
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <AddSliderPhotoContainer>
        <AddSliderPhotoTitle variant="h4" component="h4">
          Edit Photo
        </AddSliderPhotoTitle>
        <AddSliderPhotoForm onSubmit={handleSubmit}>
          <TextField
            label="order"
            name="order"
            type="number"
            value={sliderPhoto.order}
            onChange={handleChange}
            required
          /> 
          <FormControlLabel
            control={
              <Checkbox
                name="is_active"
                checked={sliderPhoto.is_active}
                onChange={handleChange}
              />
            }
            label="Status"
            labelPlacement="end" style={{ color: "black" }}
          /> 
          
          <Button type="submit" variant="contained" color="primary">
            Save Slider Photo
          </Button>
        </AddSliderPhotoForm>
      </AddSliderPhotoContainer>
    </Box>
  );
};

export default EditSliderPhoto;
