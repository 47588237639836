// ScrollToTop.js
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const ScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            // Scroll to the top of the page on route change
            window.scrollTo(0, 0);
        });

        // Cleanup function to unsubscribe when the component is unmounted
        return () => {
            unlisten(); // Unsubscribe to avoid memory leaks
        };
    }, [history]);

    return null;
};
