// import React from "react"
// import { Heading } from "../common/Heading"
// import { contact } from "../data/dummydata"

// export const Contact = () => {
//   return (
//     <>
//       <div className='contact'>
//         <div className='container'>
//           <Heading title='Keep In Touch' />
//           <div className='content flexsb'>
//             <form>
//               <div className='flex'>
//                 <input type='text' placeholder='Name' data-aos='flip-left' />
//                 <input type='email' placeholder='Email' data-aos='flip-right' />
//               </div>
//               <input type='email' placeholder='Subject' data-aos='flip-up' />
//               <textarea name='' placeholder='Message' id='' cols='30' rows='10' data-aos='flip-down'></textarea>
//               <button data-aos='zoom-in-up'>Submit</button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

import React from 'react';
import './css/about-us.css';
import { normalIcon } from '../data/dummydata';


export const Contact = () => {
  return (
    <div className="contact-us-container">
      <div className="contact-us-video" data-aos="fade-down">
        <iframe id="ytplayer" type="text/html" width="560" height="500"
          src="https://www.youtube.com/embed/DRWOvK7mDeg?autoplay=1&loop=1modestbranding=1"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowfullscreen>
        </iframe>
      </div>
      <div className='contact-us-social'>
        <h1> Join Us as a Reseller!</h1>
        <p>If you need any assistance, feel free to reach us at: </p>
        <p>WhatsApp:<a href="https://wa.me/+6285174405750"> <u> +62 851 74405750 </u> </a></p>
        <p>Email:<a href="mailto:holvend.official@gmail.com"> <u> holvend.official@gmail.com </u> </a></p>
        {/* <div>
          {normalIcon.map((item, index) => (
            <React.Fragment key={index}>
              <a href={item.link}>
                <i style={{ fontSize: '25px', padding: '0 10px' }} data-aos='zoom-in'>{item.icon}</i>
              </a>
            </React.Fragment>
          ))}
        </div> */}
      </div>
    </div>

  );
};