import React, { useState, useEffect } from 'react';
import api from '../middleware/api';
import { useHistory, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2';
import Sidebar from "../common/Sidebar"
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from 'react-select';
const AddProductContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginTop: '20px',
  marginLeft: '20px',
  width: '100%'

});

const AddProductTitle = styled(Typography)(({ theme }) => ({
  marginTop: '64px',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  }
}));

const AddProductForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '50%'
});

const PhotoTableContainer = styled(TableContainer)({
  marginTop: '20px',
});

export const EditProduct = () => {
  const { id } = useParams();
  const history = useHistory();
  const [product, setProduct] = useState({
    product: '',
    productCode: '',
    description: '',
    description_en: '',
    is_active: true,
    size: '',
    link_tokopedia: '',
    link_shopee: '',
    link_wa: '',
    product_category: ''
  });
  const [photos, setPhotos] = useState([]);

  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setProduct(prevProduct => ({
      ...prevProduct,
      [name]: inputValue,
    }));
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (id) {
      const fetchData = async () => {
        let option = []
        try {
          const response = await api.get('https://api.holvend.com/api/category');
          const categoryList = response.data.payload.datas;


          for (let category of categoryList) {
            let Category = {
              value: category.category_code,
              label: category.category_name,
            };
            //productCategoryResponse.push(categoryCode)
            option.push(Category)
          }
          setOptions(option);

        } catch (error) {
          console.error('Error fetching options:', error);
        }


        try {
          const response = await api.get(`https://api.holvend.com/api/product/${id}`);
          const { product, productCode, description, description_en, productPhotos, is_active, size, link_tokopedia, link_shopee, link_wa, product_category } = response.data.payload.datas;

          setProduct({
            product,
            productCode,
            description,
            description_en,
            is_active,
            size,
            link_tokopedia,
            link_shopee,
            link_wa
          });

          const option2 = [];
          for (let category of product_category) {
            const foundCategory = option.find(x => x.value === category.category_code);
            const foundIndex = option.findIndex(x => x.value === category.category_code);

            console.log(foundCategory);
            console.log(option);


            if (foundIndex !== -1) {
              option2.push(option[foundIndex]);
            }
          }
          setSelectedValues(option2);


          for (const obj of productPhotos) {
            const newPhoto = [{ name: obj.photo_name, id: obj.id, path: obj.photo_path }];
            setPhotos(prevPhotos => [...prevPhotos, ...newPhoto]);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();

    }
  }, []);


  const handlePhotoUpload = (event) => {
    const uploadedPhotos = Array.from(event.target.files);
    const validPhotos = uploadedPhotos.filter(photo => {
      const fileType = photo.type;
      if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/webp') {
        showPopup('Invalid File Type', 'error');
      }
      return fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/webp';
    });
    setPhotos(prevPhotos => [...prevPhotos, ...validPhotos]);
  };
  const handlePhotoView = (photo) => {
    if (photo.id !== undefined) {
      const tempAnchor = document.createElement('a');
      tempAnchor.href = photo.path;
      tempAnchor.download = photo.name;
      tempAnchor.target = "_blank";
      document.body.appendChild(tempAnchor);
      tempAnchor.click();
      document.body.removeChild(tempAnchor);
    } else {

      const fileURL = URL.createObjectURL(photo);
      const tempAnchor = document.createElement('a');
      tempAnchor.href = fileURL;
      tempAnchor.target = "_blank";

      document.body.appendChild(tempAnchor);
      tempAnchor.click();
      document.body.removeChild(tempAnchor);

      // Hapus URL objek setelah selesai
      URL.revokeObjectURL(fileURL);
    }
  };

  const handlePhotoDelete = (index) => {
    const shouldDelete = window.confirm('Apakah Anda yakin ingin menghapus foto ini?');

    if (shouldDelete) {
      setPhotos(prevPhotos => prevPhotos.filter((_, i) => i !== index));
    }
  };


  const showPopup = (message, type) => {
    return Swal.fire({
      title: message,
      icon: type,
      confirmButtonText: 'OK',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    let listPhoto = []
    let categories = []
    for (let category of selectedValues) {
      categories.push(category.value)
    }
    photos.forEach((photo, index) => {
      formData.append('file', photo);
      if (photo.id != undefined) {
        listPhoto.push(photo.id)
      }
    });
    var data = {
      'productCode': product.productCode,
      'product': product.product,
      'description': product.description,
      'description_en': product.description_en,
      'is_active': product.is_active,
      'productPhoto': listPhoto,
      'size': product.size,
      'link_tokopedia': product.link_tokopedia,
      'link_shopee': product.link_shopee,
      'link_wa': product.link_wa,
      'product_category': categories
    };
    formData.append('data', JSON.stringify(data));
    //Handle add product logic
    api.patch('https://api.holvend.com/api/product', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(response => {
      showPopup('Product Edit', 'success').then(() => {
        history.push('/productspaging'); // Redirect to home page after successful add
      });
    }).catch(error => {
      if (error.response && error.response.status === 409) {
        showPopup('Product Code Not Unique', 'error');
      } else {
        console.error(error);
      }
    });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <AddProductContainer>
        <AddProductTitle variant="h4" component="h4">
          Edit Product
        </AddProductTitle>
        <AddProductForm onSubmit={handleSubmit}>
          <TextField
            label="Product Code"
            name="productCode"
            value={product.productCode}
            onChange={handleChange}
            disabled={id}
            required
          />
          <TextField
            label="Product Name"
            name="product"
            value={product.product}
            onChange={handleChange}
            required
          />
          <TextField
            label="size"
            name="size"
            value={product.size}
            onChange={handleChange}
            required
          />
          <FormControl>
            <FormLabel>Link Tokopedia</FormLabel>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter link tokopedia"
              name="link_tokopedia"
              value={product.link_tokopedia}
              onChange={handleChange}
              style={{ width: '100%', resize: 'vertical' }}

            />
          </FormControl>
          <FormControl>
            <FormLabel>Link Shopee</FormLabel>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter link shopee"
              name="link_shopee"
              value={product.link_shopee}
              onChange={handleChange}
              style={{ width: '100%', resize: 'vertical' }}

            />
          </FormControl>
          <FormControl>
            <FormLabel>Link WA</FormLabel>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter link wa"
              name="link_wa"
              value={product.link_wa}
              onChange={handleChange}
              style={{ width: '100%', resize: 'vertical' }}

            />
          </FormControl>
          {/* <FormControl>
            <FormLabel>Category</FormLabel>
            <select
              name="product_category"
              value={product.product_category}
              onChange={handleChange}
              style={{ width: '100%', height: '50px' }}
              required
            >
              <option value="" disabled>Select an option  </option>
              <option value="MensBeardShave">Mens Beard Shave</option>
              <option value="HairProducts">Hair Products</option>
              <option value="Pomades">Pomades</option>
              <option value="ApparelMerch">Apparel & Merch</option>
            </select>
          </FormControl> */}
          <div>
            <FormLabel>Category</FormLabel>
            <Select
              value={selectedValues}
              isMulti
              name="colors"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleSelectChange}
            />
          </div>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter descriptions"
              name="description"
              value={product.description}
              onChange={handleChange}
              style={{ width: '100%', resize: 'vertical' }}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description English</FormLabel>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter descriptions"
              name="description_en"
              value={product.description_en}
              onChange={handleChange}
              style={{ width: '100%', resize: 'vertical' }}

            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                name="is_active"
                checked={product.is_active}
                onChange={handleChange}
              />
            }
            label="Status"
            labelPlacement="end" style={{ color: "black" }}
          />


          <label htmlFor="photo-upload">Upload Photo</label>
          <input id="photo-upload" type="file" multiple onChange={handlePhotoUpload} />
          <PhotoTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Photo</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {photos.map((photo, index) => (
                  <TableRow key={index}>
                    <TableCell>{photo.name}</TableCell>
                    <TableCell>
                      <Button variant="outlined" onClick={() => handlePhotoView(photo)}>
                        View
                      </Button>
                      <Button variant="outlined" onClick={() => handlePhotoDelete(index)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PhotoTableContainer>
          <Button type="submit" variant="contained" color="primary">
            Save Product
          </Button>
        </AddProductForm>
      </AddProductContainer>
    </Box>
  );
};

export default EditProduct;
