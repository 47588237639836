import React, { useState, useEffect } from 'react';
import api from '../../middleware/api';
import Sidebar from "../../common/Sidebar"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import '../dashboard.css';

const ViewJobsContainer = styled('div')({
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const ListProductTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const AddProductButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    padding: '8px',
  },
}));

const TableHoverRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transition: 'background-color 0.2s ease',
  },
}));

export const SliderPhotoPaging = () => {
  const history = useHistory();
  const [jobsStateArray, setJobsStateArray] = useState([]);

  useEffect(() => {
    api.get('https://api.holvend.com/api/sliderphoto/all')
      .then(response => {
        console.log(response.data.payload.datas)
        setJobsStateArray(response.data.payload.datas);
      }).catch(error => {
        if (error.message === 'Token expired') {
          window.location.reload();
        } 
      });
  }, []);

  const handleAddProduct = () => {
    history.push('/sliderphotodetail');
  };

  const handleEdit = (id) => {
    history.push(`/sliderphotodetail/${id}`);
  }; 
  const handleDelete = (id) => {
    const shouldDelete = window.confirm('Apakah Anda yakin ingin menghapus product ini?');

    if (shouldDelete) {
      api.delete(`https://api.holvend.com/api/sliderphoto/${id}`)
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        if (error.message === 'Token expired') {
          window.location.reload();
        } 
      });
    }
   
  };
  
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <ViewJobsContainer sx={{ flexGrow: 1, p: 3 }} >
          <DrawerHeader />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListProductTitle variant="h4" component="h4">List Photo</ListProductTitle>
            <AddProductButton variant="contained" color="primary" onClick={handleAddProduct}>
              Add
            </AddProductButton>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order</TableCell> 
                  <TableCell>Url</TableCell> 
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsStateArray.map((item, index) => (
                  <TableHoverRow key={index}>
                    <TableCell>{item.order}</TableCell>
                    <TableCell>{item.photo_path}</TableCell> 
                    <TableCell>{item.is_active ? 'Active' : 'Inactive'}</TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleEdit(item.id)} sx={{
                        marginRight: {
                          md: '4px',
                          sm: '0px',
                        }
                      }}>Edit</Button>
                      <Button variant="contained" color="error" onClick={() => handleDelete(item.id)} sx={{ marginLeft: {
                          md: '4px',
                          sm: '0px',
                        } }}>Delete</Button>
                    </TableCell>
                  </TableHoverRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ViewJobsContainer>
      </Box>
    </>
  );
};

export default SliderPhotoPaging;
