import React, { useState } from "react";
import './css/Products.css';
export const Gallery = ({ imgs = [{ url: "" }] }) => {
  const [mainImage, setMainImage] = useState(imgs[0]);

  const selectImage = (index) => {
    setMainImage(imgs[index]);
  };

  return (
    <div className="gallery" tyle={{ display: 'flex' }} data-aos="fade-right" >
      <div className="main-image">
        <img
          src={mainImage.photo_path}
          alt={mainImage.filename}
          className="main-image--style"
        />
      </div>
      <div className="thumbnail-images">
        {imgs.map((curElm, index) => (
          <figure key={index}>
            <img
              src={curElm.photo_path}
              alt={curElm.filename}
              className="thumbnail-image--style"
              // Mengatur lebar maksimum gambar kecil
              onClick={() => selectImage(index)}
            />
          </figure>
        ))}
      </div>
    </div>
  );
};
