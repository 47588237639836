import React from 'react';
import './css/about-us.css';

export const About = () => {
  return (
    <div>
      <div className="about-us-container">
        <div className="about-us-content">
          <h2 data-aos="fade-down">HOLVEND</h2>
          <p data-aos="fade-down" data-aos-duration="2000">
            {/* Holvend was founded by Indonesian barber, Glendy Peruge from Vonnette Barbershop in 2023.
            The name 'Holvend' was planned by Glenn several years before, as he has many years of experience in hair styling and men's grooming.
            In 2023, Glenn and his friend, Dhio, started to operate and develop the products.
            Now, they have created new products for barbers, stylists, and those interested in hair styling. */}
            Holvend was founded by Vonnette’s Barber in 2023 with many years of experience in hair styling and men's grooming. Short story, from the first year we operate Vonnette Barbershop, we loved hair products and sold many local and international products who inspired us a lot.
            The name 'Holvend' was planned several years before. In 2023 we created new products for barbers, stylists, and those interested in hair styling.
            <br />
          </p>
          <strong> Our styles work everywhere. </strong>
        </div>

        <div className="about-us-video" data-aos="fade-down">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/RIvcdymmvz4?loop=1&modestbranding=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen>
          </iframe>
        </div>
      </div>
      <div className="about-us-container">
        <div className="about-us-image" data-aos="fade-down">
          <img src="./images/IMG_7691.webp" alt="About Us" />
        </div>
        <div className="about-us-content second-content">
          <h2 data-aos="fade-down">WHAT DO WE PROVIDE</h2>
          <p data-aos="fade-down">
            {/* Holvends products are thoughtfully designed and expertly formulated specifically for professional barbers. Our heritage in Dutch barbering traditions has inspired us to develop a range of grooming essentials that meet the exacting standards of the most discerning barbers. With Holvends, barbers can trust in the quality and performance of our products, elevating their craft to new heights and ensuring their clients leave the chair feeling and looking their best. */}
            With a passionate commitment to hair products and men's grooming, we offer several choices of products made from natural ingredients to keep your hair clean, tidy, and well cared for. These products are suitable for all hair types and styles.
          </p>
          <br />
          <br />
          <h2 data-aos="fade-down">WHAT DO WE AIM</h2>
          <p data-aos="fade-down">
            {/* Our mission is to make the everyday difficulties of hair styling easier, and to solve the challenges that hairdressers regularly face. Most importantly we believe that beauty is a kind of happiness too, and that feeling good about the way you look is important. Therefore Holvend want to give you the best hair styling products.</p> */}
            Our mission is to make the everyday difficulties of hair styling easier, and to solve the challenges that hairdressers regularly face. Most importantly we believe that beauty is a kind of happiness too, and that feeling good about the way you look is important. Therefore Holvend want to give you the best hair styling products.</p>
        </div>
      </div>

      <div className="product-advantages">
        <div className="advantage">
          <div className="advantage-image" data-aos="fade-down">
            <img src="./images/bpom.webp" alt="BPOM Verified" />
          </div>
          <div className="advantage-title">
            <p>BPOM Verified</p>
          </div>
        </div>
        <div className="advantage">
          <div className="advantage-image" data-aos="fade-down">
            <img src="./images/parabenfree.webp" alt="Paraben Free" />
          </div>
          <div className="advantage-title">
            <p>Paraben Free</p>
          </div>
        </div>
        <div className="advantage">
          <div className="advantage-image" data-aos="fade-down">
            <img src="./images/natural.webp" alt="100% Natural" />
          </div>
          <div className="advantage-title">
            <p>100% Natural</p>
          </div>
        </div>
        <div className="advantage">
          <div className="advantage-image" data-aos="fade-down">
            <img src="./images/crueltyfree.webp" alt="Cruelty Free" />
          </div>
          <div className="advantage-title">
            <p>Cruelty Free</p>
          </div>
        </div>
      </div>
    </div>
  );
};
