import React from 'react';
import './css/image-grid.css'; // Import your CSS file for styling
import { dummyCarousel, dummyGrid, dummyProduct, homePhotos } from '../data/dummydata';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Aos from 'aos';
const ImageGrid = () => {
  const history = useHistory();
  const navigateToDetail = (productCode) => {
    history.push(`/shop/${productCode}`);
  };

  const url = "https://api.holvend.com/api/product/active";
  const [products, setProduct] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);

  const fetchProduct = async () => {
    try {
      const response = await axios.get(url);
      setProduct(response.data.payload.datas);
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Change the currentImage state to cycle through images
      setCurrentImage((prevImage) => (prevImage + 1) % homePhotos.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [homePhotos]); // Dependency on homePhotos ensures the effect updates when homePhotos changes


  return (
    <div className='image-grid-container'>
      {/* {products.map((x, index) => (
        <div key={index}
          className={`content-product container ${index % 2 === 0 ? 'even' : 'odd'}`}>
          <div className="content-text">
            <h3 className="h3-product-intro">
              {x.product}
            </h3>
            <p style={{ color: 'black' }}>
              {x.description_en}
            </p>
            <button className="button-show" onClick={() => navigateToDetail(x.productCode)}>Browse Product</button>
          </div>
          <div className="content-photo zoom-basic"
            data-aos={`${index % 2 === 0 ? 'fade-right' : 'fade-left'}`}>
            {x.productPhotos[0] && x.productPhotos[0].photo_path ? (
              <img src={x.productPhotos[0].photo_path} alt={x.product} />
            ) : (
              <div> </div>
            )}
          </div>
        </div>
      ))} */}

      <div className="image-grid">
        {homePhotos.map((item, index) => (
          <div key={index} className={`grid-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
            <div className="image">
              <img data-aos={`${index % 2 === 0 ? 'fade-right' : 'fade-left'}`}
                src={products[randomProductIndexes[index]]?.productPhotos[0].photo_path} />
            </div>
            <div className="foto-pelengkap">
              <div className="image">
                <img data-aos={`${index % 2 === 0 ? 'fade-right' : 'fade-left'}`}
                  src={item.image} alt={`Image ${index + 1}`} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
function getRandomIndexes(length) {
  const indexes = [];
  while (indexes.length < length) {
    const randomIndex = Math.floor(Math.random() * length);
    if (!indexes.includes(randomIndex)) {
      indexes.push(randomIndex);
    }
  }
  return indexes;
}

// Mendapatkan indeks acak untuk produk
const randomProductIndexes = getRandomIndexes(homePhotos.length);


export default ImageGrid;