import React from "react"
import { Hero } from "./Hero"
import { Video } from "./Video"
import { ProductsIntro } from "../pages/ProductsIntro"
import { About } from "../pages/About"
import { AboutHome } from "../pages/AboutHome"
import ImageGrid from "../pages/ImageGrid"

export const Home = () => {
  return (
    <>
      {/* <Hero /> */}
      {/* <About /> */}
      {/* <ProductsIntro /> */}
      {/* <Contact /> */}
      <Video />
      <ImageGrid />
      <AboutHome />
    </>
  )
}
