import React, { useState, useEffect } from 'react';
import api from '../../middleware/api';
import Sidebar from "../../common/Sidebar"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import '../dashboard.css';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';  
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
const ViewJobsContainer = styled('div')({
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const ListProductTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const AddProductButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    padding: '8px',
  },
}));

const TableHoverRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transition: 'background-color 0.2s ease',
  },
}));
const showPopup = (message, type) => {
  return Swal.fire({
    title: message,
    icon: type,
    confirmButtonText: 'OK',
  });
};
const ChangePasswordForm = ({ open, onClose, user }) => {
  const [newPassword, setNewPassword] = useState('');
  const handleChangePassword = () => {  
    var data={
      username: user.username,
      password: newPassword
    }
    onClose();
        api.patch('https://api.holvend.com/api/user', data, {
          headers: {
            'Content-Type': 'application/json',
          },  
        }).then(response => {
          showPopup('Change Password', 'success').then(() => {
          });
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            showPopup(error.response.data, 'error');
          } else {
            console.error(error);
          }
        });

    };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        {/* Close button */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Title */}
        <Typography variant="h6" component="h2" gutterBottom>
          {/* Reset Password for {user.username} */}
        </Typography>
        <TextField
          label="New Password"
          variant="outlined"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleChangePassword}>
          Change Password
        </Button>
      </Box>
    </Modal>
  );
};
const AddUserForm = ({ open, onClose }) => {
  const [newPassword, setNewPassword] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const handleAddPassword = () => {  
    var data={
      username: newUsername,
      password: newPassword
    }
    onClose();
        api.post('https://api.holvend.com/api/user', data, {
          headers: {
            'Content-Type': 'application/json',
          },  
        }).then(response => {
          showPopup('Change Password', 'success').then(() => {
          });
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            showPopup(error.response.data, 'error');
          } else {
            console.error(error);
          }
        });

    };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        {/* Close button */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Title */}
        <Typography variant="h6" component="h2" gutterBottom>
          {/* Reset Password for {user.username} */}
        </Typography>
        <TextField
          label="Username"
          variant="outlined"
          type="text"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleAddPassword}>
          Add User
        </Button>
      </Box>
    </Modal>
  );
};
export const UsersPaging = () => {
  const history = useHistory();
  const [jobsStateArray, setJobsStateArray] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [isModalAddUser, setIsModalAddUser] = useState(false);  
  
  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  }; 
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleAddModal = () => { 
    setIsModalAddUser(true);
  }; 
  const handleCloseAddModal = () => {
    setIsModalAddUser(false);
  };
  useEffect(() => {
    api.get('https://api.holvend.com/api/user')
      .then(response => {
        console.log(response.data.payload.datas)
        setJobsStateArray(response.data.payload.datas);
      }).catch(error => {
        if (error.message === 'Token expired') {
          window.location.reload();
        } 
      });
  }, []);

 

  // const handleEdit = (id) => {
  //   history.push(`/userdetail/${id}`);
  // }; 
  const handleDelete = (id) => {
    const shouldDelete = window.confirm('Apakah Anda yakin ingin menghapus user ini?');

    if (shouldDelete) {
      api.delete(`https://api.holvend.com/api/user/${id}`)
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        if (error.message === 'Token expired') {
          window.location.reload();
        } 
      });
    }
   
  };
  
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <ViewJobsContainer sx={{ flexGrow: 1, p: 3 }} >
          <DrawerHeader />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListProductTitle variant="h4" component="h4">List User</ListProductTitle>
            <AddProductButton variant="contained" color="primary" onClick={handleAddModal}>
              Add
            </AddProductButton>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>  
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsStateArray.map((item, index) => (
                  <TableHoverRow key={index}>
                    <TableCell>{item.username}</TableCell> 
                    <TableCell>
                      <Button variant="contained" onClick={() => handleEdit(item)} sx={{
                        marginRight: {
                          md: '4px',
                          sm: '0px',
                        }
                      }}>Change Password</Button>
                      <Button variant="contained" color="error" onClick={() => handleDelete(item.username)} sx={{ marginLeft: {
                          md: '4px',
                          sm: '0px',
                        } }}>Delete</Button>
                    </TableCell>
                  </TableHoverRow>
                ))}
              </TableBody>
               <ChangePasswordForm open={isModalOpen} onClose={handleCloseModal} user={selectedUser} />
               <AddUserForm open={isModalAddUser} onClose={handleCloseAddModal}  />
            </Table>
          </TableContainer>
        </ViewJobsContainer>
      </Box>
    </>
  );
};

export default UsersPaging;
