import React, { useState } from 'react';
import api from '../../middleware/api';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2';
import Sidebar from "../../common/Sidebar"
import FormControlLabel from '@mui/material/FormControlLabel';

const AddSliderPhotoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column', 
  marginTop: '20px',
  marginLeft: '20px',
  minWidth: '100%',
  
});

const AddSliderPhotoTitle = styled(Typography)(({ theme }) => ({
  marginTop: '64px',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const AddSliderPhotoForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '50%'
});

const SliderPhotoTableContainer = styled(TableContainer)({
  marginTop: '20px',
});

export const AddSliderPhoto = () => {
  const history = useHistory();
  const [sliderPhoto, setSliderPhoto] = useState({
    order: '',
    is_active: true
  });
  const [photos, setPhotos] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setSliderPhoto(prevProduct => ({
      ...prevProduct,
      [name]: inputValue,
    }));
  };

  const handlePhotoUpload = (event) => {
    const uploadedPhotos = Array.from(event.target.files);
    
    const validPhotos = uploadedPhotos.filter(photo => {
      const fileType = photo.type;
     
      if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/webp') {
        showPopup('Invalid File Type', 'error');
      }
      return fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/webp';
    });
  
    // Menimpa foto yang sudah ada dengan foto yang baru diunggah
    setPhotos(validPhotos);
  };

  const handlePhotoDelete = (index) => {
    const shouldDelete = window.confirm('Apakah Anda yakin ingin menghapus foto ini?');

    if (shouldDelete) {
      setPhotos(prevPhotos => prevPhotos.filter((_, i) => i !== index));
    }
  };

  // Fungsi untuk menangani unduhan foto
  const handlePhotoView = (photo) => {
    // Jika photo.id tidak terdefinisi (berarti Anda memiliki objek File)
    const fileURL = URL.createObjectURL(photo);
    const tempAnchor = document.createElement('a');
    tempAnchor.href = fileURL;  // Beri nama file saat diunduh
    tempAnchor.target = "_blank"; // Buka di jendela baru

    // Tambahkan elemen anchor ke body (sementara) agar dapat di-klik otomatis
    document.body.appendChild(tempAnchor);

    // Klik elemen anchor secara otomatis untuk memulai unduhan
    tempAnchor.click();

    // Hapus elemen anchor setelah selesai
    document.body.removeChild(tempAnchor);

    // Hapus URL objek setelah selesai
    URL.revokeObjectURL(fileURL);

  };


  const showPopup = (message, type) => {
    return Swal.fire({
      title: message,
      icon: type,
      confirmButtonText: 'OK',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var data = {
      'order': sliderPhoto.order,
      'is_active': sliderPhoto.is_active,
    };
    formData.append('data', JSON.stringify(data));
    photos.forEach((photo, index) => {
      formData.append('file', photo);
    });

    api.post('https://api.holvend.com/api/sliderphoto', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(response => {
      showPopup('Slider Photo Added', 'success').then(() => {
        history.push('/sliderphotopaging'); // Redirect to home page after successful add
      });
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        showPopup( 'Photo Required', 'error');
      } else {
        console.error(error);
      }
    });
  };

  return (
    <Box  sx={{ display: 'flex' }}>
      <Sidebar />
      <AddSliderPhotoContainer>
        <AddSliderPhotoTitle variant="h4" component="h4">
          Add Photo
        </AddSliderPhotoTitle>
        <AddSliderPhotoForm onSubmit={handleSubmit}>
          <TextField
            label="order"
            name="order"
            type="number"
            value={sliderPhoto.order}
            onChange={handleChange}
            required
          /> 
          <FormControlLabel
            control={
              <Checkbox
                name="is_active"
                checked={sliderPhoto.is_active}
                onChange={handleChange}
              />
            }
            label="Status"
            labelPlacement="end" style={{ color: "black" }}
          /> 
          <label htmlFor="photo-upload">Upload Photo</label>
          <input id="photo-upload" type="file"  onChange={handlePhotoUpload} />
          <SliderPhotoTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Photo</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {photos.map((photo, index) => (
                  <TableRow key={index}>
                    <TableCell>{photo.name}</TableCell>
                    <TableCell>
                      <Button variant="outlined" onClick={() => handlePhotoView(photo)}>
                        View
                      </Button>
                      <Button variant="outlined" onClick={() => handlePhotoDelete(index)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SliderPhotoTableContainer>
          <Button type="submit" variant="contained" color="primary">
            Save Slider Photo
          </Button>
        </AddSliderPhotoForm>
      </AddSliderPhotoContainer>
    </Box>
  );
};

export default AddSliderPhoto;
