import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './login.css';
import axios from 'axios';
export const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 3);
  let isAuthenticated = JSON.parse(localStorage.getItem('is_authenticated'));
  useEffect(() => {
   isAuthenticated = JSON.parse(localStorage.getItem('is_authenticated'));
    if (isAuthenticated === true) {
      setIsAuthenticated(true);

    } else {
      // 
    }
  }, [setIsAuthenticated]);

  const handleLogin = e => {
    e.preventDefault();

    // Kirim permintaan ke API untuk memverifikasi login

    axios.post('https://api.holvend.com/api/auth/login', {
      username,
      password,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.data)
      .then(data => {
        if (data.token) {
          const token = data.token;
          localStorage.setItem('token', token);
          Swal.fire({
            timer: 1500,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              localStorage.setItem('is_authenticated', true);
              setIsAuthenticated(true);

              Swal.fire({
                icon: 'success',
                title: 'Successfully logged in!',
                showConfirmButton: false,
                timer: 1500,
              });
            },
          });
        } else {
          Swal.fire({
            timer: 1500,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Incorrect username or password.',
                showConfirmButton: true,
              });
            },
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.response.data,
          showConfirmButton: true,
        });
      });
  };

  if(!isAuthenticated){
    return (
      <div className="small-container">
        <form onSubmit={handleLogin}>
          <h1>Admin Login</h1>
          <label htmlFor="username">Username</label>
          <input
            id="username"
            type="text"
            name="username"
            placeholder=""
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            placeholder=""
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <input style={{ marginTop: '12px' }} type="submit" value="Login" />
        </form>
      </div>
    );
  } 
};