import React from 'react';

export const Video = () => {
  return (
    <div data-aos="fade-down">
      <video autoPlay loop muted style={{ width: '100%' }}>
        <source src="./video/Holvend2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};






