import React from "react"
import { social } from "../data/dummydata"

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <>
      <footer>
        {social.map((item, index) => (
          <React.Fragment key={index}>
            <a href={item.link}>
              <i style={{ fontSize: '25px', padding: '0 10px' }} data-aos='zoom-in'>{item.icon}</i>
            </a>
          </React.Fragment>
        ))}
        <p>© Holvend {year}. All rights reserved. Website by ByeByeTeam.</p>
      </footer>
    </>
  )
}

export default Footer
