import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Footer from "../common/Footer"
import { Header } from "../common/Header"
import { Home } from "../home/Home"
import { About } from "./About"
import { Contact } from "./Contact"
import { Portfolio } from "./Portfolio"
import { ComingSoon } from "./ComingSoon"
import { Products } from "./Products"
import { ProductsByCategory } from "./ProductsByCategory"
import { Login } from "../auth/Login"
import { Logout } from "../auth/logout"
import { ProductsPaging } from "./../dashboard/ProductsPaging"
import { UsersPaging } from "./../dashboard/user/userPaging"
import { ProductDetail } from "./ProductDetail"
import { AddProduct } from "./../dashboard/productForm"
import { EditProduct } from "./../dashboard/productEditForm"
import { AddSliderPhoto } from "./../dashboard/slider/sliderPhotoForm"
import { EditSliderPhoto } from "./../dashboard/slider/sliderPhotoEditForm"
import { SliderPhotoPaging } from "./../dashboard/slider/sliderPhotosPaging"
import { ScrollToTop } from "./ScrollToTop"

import { CategoryPaging } from "./../dashboard/category/categoryPaging"
import { AddCategory } from "./../dashboard/category/categoryForm"
import { EditCategory } from "./../dashboard/category/categoryEditForm"
export const Pages = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    let isAuthenticatedLocalStorage = JSON.parse(localStorage.getItem('is_authenticated'));
    setIsAuthenticated(isAuthenticatedLocalStorage);
    setIsCheckingAuth(false);
  }, []);

  if (isCheckingAuth) {
    return;
  }

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          {/* <Route exact path="/" component={ComingSoon} /> */}
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/userspaging">
            {isAuthenticated ? (
              <>
                <UsersPaging />
              </>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/userdetail">
            {isAuthenticated ? (
              <AddProduct />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/userdetail/:id?">
            {isAuthenticated ? (
              <EditProduct />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/productspaging">
            {isAuthenticated ? (
              <>
                <ProductsPaging />
              </>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/productdetail">
            {isAuthenticated ? (
              <AddProduct />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/productdetail/:id?">
            {isAuthenticated ? (
              <EditProduct />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route exact path="/categorypaging">
            {isAuthenticated ? (
              <>
                <CategoryPaging />
              </>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/categorydetail">
            {isAuthenticated ? (
              <AddCategory />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/categorydetail/:id?">
            {isAuthenticated ? (
              <EditCategory />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/sliderphotopaging">
            {isAuthenticated ? (
              <>
                <SliderPhotoPaging />
              </>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/sliderphotodetail">
            {isAuthenticated ? (
              <AddSliderPhoto />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route exact path="/sliderphotodetail/:id?">
            {isAuthenticated ? (
              <EditSliderPhoto />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route exact path="/login">
            {isAuthenticated ? <Redirect to="/productspaging" /> : <Login setIsAuthenticated={setIsAuthenticated} />}
          </Route>

          <Route>
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/shop" component={Products} />
              <Route exact path="/shop/:category" component={ProductsByCategory} />
              <Route exact path="/shop/products/:code" component={ProductDetail} />
              <Route exact path="/portfolio" component={Portfolio} />
              <Route exact path="/contact" component={Contact} />
              <Redirect to="/" />
            </Switch>
            <Footer />
          </Route>
        </Switch>
      </Router>
    </>
  )
}