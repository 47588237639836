import React from 'react';
import './css/about-us.css';
import { useEffect } from 'react';
import Aos from 'aos';

export const AboutHome = () => {


  useEffect(() => {
    Aos.init({
      duration: 3000,
    })
  }, []);

  return (
    <div>
      <div className="home-video-container">
        <div className="video-caption" data-aos="zoom-in">
          <h2>
            "Crafting History, Weaving Legacies"
          </h2>
          <h1>

          </h1>
        </div>
        <div className="home-video" data-aos="fade-down">
          <iframe
            // src="https://www.youtube.com/embed/RIvcdymmvz4?loop=1&modestbranding=1"
            src="https://www.youtube.com/embed/RB5rxVczYOw?si=npm4Ugj4rmUvArPs?loop=1&modestbranding=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen>
          </iframe>
        </div>
      </div>

      <div className="product-advantages">
        <div className="advantage">
          <div className="advantage-image" data-aos="fade-down">
            <img src="./images/bpom.webp" alt="BPOM Verified" />
          </div>
          <div className="advantage-title">
            <p>BPOM Verified</p>
          </div>
        </div>
        <div className="advantage">
          <div className="advantage-image" data-aos="fade-down">
            <img src="./images/parabenfree.webp" alt="Paraben Free" />
          </div>
          <div className="advantage-title">
            <p>Paraben Free</p>
          </div>
        </div>
        <div className="advantage">
          <div className="advantage-image" data-aos="fade-down">
            <img src="./images/natural.webp" alt="100% Natural" />
          </div>
          <div className="advantage-title">
            <p>100% Natural</p>
          </div>
        </div>
        <div className="advantage">
          <div className="advantage-image" data-aos="fade-down">
            <img src="./images/crueltyfree.webp" alt="Cruelty Free" />
          </div>
          <div className="advantage-title">
            <p>Cruelty Free</p>
          </div>
        </div>
      </div>

    </div>
  );
};
