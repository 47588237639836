import axios from 'axios';
import jwt_decode from 'jwt-decode';

// Membuat singleton instance Axios
const api = axios.create({
  baseURL: 'https://api.holvend.com',
});

// Menambahkan interceptor untuk permintaan
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (decodedToken.exp < currentTimestamp) {
        localStorage.removeItem('token');
        localStorage.removeItem('is_authenticated');
        
        throw new Error('Token expired');
      }
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Menambahkan interceptor untuk respons
api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default api;
