import React from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import './css/Products.css'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Aos from 'aos';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const ProductsByCategory = () => {
    const history = useHistory();
    const { category } = useParams();
    const urlCategory = `https://api.holvend.com/api/product/category/${category}`;
    const [products, setProduct] = useState([]);
    const [categories, setCategory] = useState([]);
    const [currCategory, setCurrCategory] = useState(null);
    const [descCategory, setDescCategory] = useState(null);

    const fetchCategory = async () => {
        const url = "https://api.holvend.com/api/category";
        const categoriestemp = []
        try {
            const response = await axios.get(url);
            setCategory(response.data.payload.datas);
            const get = response.data.payload.datas.find(x => x.category_code === category)
            setCurrCategory(get.category_name);
            setDescCategory(get.description)
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    }

    const navigateToDetail = (productCode) => {
        history.push(`/shop/products/${productCode}`);
    };

    // const fetchProduct = async () => {
    //     if (category === 'Apparel&Merch') {
    //         setProduct([]);
    //     } else {
    //         try {
    //             const response = await axios.get(urlCategory);
    //             setProduct(response.data.payload.datas);
    //         } catch (error) {
    //             console.error('Error fetching product:', error);
    //         }
    //     }
    // }

    const fetchProduct = async () => {
        try {
            const response = await axios.get(urlCategory);
            setProduct(response.data.payload.datas);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    }

    useEffect(() => {
        fetchCategory();
        fetchProduct();
        Aos.init({
            duration: 1500,
        })
        setProduct([]);
    }, [history.location.pathname]);

    // const categoryShop = [
    //     {
    //         url: "/shop/Pomades",
    //         category: 'Pomades',
    //         text: "HAIR STYLING",
    //     },
    //     {
    //         url: "/shop/HairProducts",
    //         category: 'HairProducts',
    //         text: "HAIR CARE",
    //     },
    //     {
    //         url: "/shop/MensBeardShave",
    //         category: 'MensBeardShave',
    //         text: "SHAVES",
    //     },
    //     {
    //         url: "/shop/",
    //         category: 'Merchandise',
    //         text: "APPAREL & MERCH",
    //     },
    // ];

    //     return (
    //         <div className="product-list">
    //             <div className='shop-category'>
    //                 <div className='shop-catDesc'>
    //                     <h1> ALL </h1>
    //                     <br />
    //                     Lorem Ipsum is simply dummy text of the printing and typesetting industry.
    //                 </div>

    //                 {categories.map((links, i) => (
    //                     // <Link to={links.url} key={i} data-aos='zoom-in-left' onClick={MenuActive}>
    //                     <Link to={links.url} key={i} data-aos='zoom-in-left'>
    //                         {links.category_name}
    //                     </Link>
    //                 ))}
    //             </div>
    //             <div className="shop-product">
    //                 {products.map((x, index) => (
    //                     <div key={index} className="product-card zoom-rotate" onClick={() => navigateToDetail(x.productCode)}>
    //                         <div className='product-card-image'>
    //                             {x.productPhotos[0] && x.productPhotos[0].photo_path ? (
    //                                 <img data-aos="fade-down" src={x.productPhotos[0].photo_path} alt={x.product} />
    //                             ) : (
    //                                 <div> </div>
    //                             )}
    //                         </div>
    //                         <h3>
    //                             {x.product}
    //                         </h3>
    //                     </div>
    //                 ))}
    //             </div>
    //         </div>
    //     );
    // };



    return (
        <div style={{
            minHeight: '75vh'
        }}>
            <div className="product-list">
                <div className='shop-category'>
                    <div className='currCategory' data-aos='zoom-in-right'>
                        <h2> {currCategory} </h2>
                        <div className='descCategory' data-aos='zoom-in-right'>
                            {descCategory}
                        </div>
                    </div>

                    <div className='navCategory' data-aos='zoom-in-right'>
                        {categories.map((links, i) => (
                            <Link to={`/shop/${links.category_code}`} key={i}
                            >
                                {links.category_name}
                            </Link>
                        ))}
                    </div>

                </div>
                {products.length > 0 ? (
                    <div className="shop-product">
                        {products.map((x, index) => (
                            <div key={index} className="product-card" data-aos="fade-down" onClick={() => navigateToDetail(x.productCode)}>
                                <div className='product-card-image'>
                                    {x.productPhotos[0] && x.productPhotos[0].photo_path ? (
                                        <img src={x.productPhotos[0].photo_path} alt={x.product} />
                                    ) : (
                                        <div> </div>
                                    )}
                                </div>
                                <h3>
                                    {x.product}
                                </h3>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='shop-product comingSoonContainer'>
                        <div className='comingSoon'>
                            <h2>Products from {currCategory} Coming Soon!</h2>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
};
