import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import './css/Products.css'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Aos from 'aos';

export const Products = () => {
    const history = useHistory();
    const [products, setProduct] = useState([]);
    const [categories, setCategory] = useState([]);

    const fetchCategory = async () => {
        const url = "https://api.holvend.com/api/category";
        try {
            const response = await axios.get(url);
            setCategory(response.data.payload.datas);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    }

    const fetchProduct = async () => {
        const url = "https://api.holvend.com/api/product/active";
        try {
            const response = await axios.get(url);
            setProduct(response.data.payload.datas);
        } catch (error) {
            setProduct([]);
            console.error('Error fetching product:', error);
        }
    }

    const navigateToDetail = (productCode) => {
        history.push(`/shop/products/${productCode}`);
    };

    useEffect(() => {
        fetchCategory();
        fetchProduct();
        Aos.init({
            duration: 1500,
        })
    }, []);

    // const categoryShop = [
    //     {
    //         url: "/shop/HairStyling",
    //         text: "HAIR STYLING",
    //     },
    //     {
    //         url: "/shop/HairCare",
    //         text: "HAIR CARE",
    //     },
    //     {
    //         url: "/shop/Shaves",
    //         text: "SHAVES",
    //     },
    //     {
    //         url: "/shop/Apparel&Merch",
    //         text: "APPAREL & MERCH",
    //     },
    // ];


    return (
        <div style={{
            minHeight: '75vh'
        }}>
            <div className="product-list">
                <div className='shop-category'>
                    <div className='currCategory' data-aos='zoom-in-right'>
                        <h2> ALL </h2>
                        <div className='descCategory' data-aos='zoom-in-right'>
                            Discover power packed product essentials that fulfill a variety of men care needs.
                        </div>
                    </div>
                    <div className='navCategory' data-aos='zoom-in-right'>
                        {categories.map((links, i) => (
                            <Link to={`/shop/${links.category_code}`} key={i}>
                                {links.category_name}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="shop-product">
                    {products.map((x, index) => (
                        <div key={index} className="product-card" data-aos="fade-down" onClick={() => navigateToDetail(x.productCode)}>
                            <div className='product-card-image'>
                                {x.productPhotos[0] && x.productPhotos[0].photo_path ? (
                                    <img src={x.productPhotos[0].photo_path} alt={x.product} />
                                ) : (
                                    <div> </div>
                                )}
                            </div>
                            <h3>
                                {x.product}
                            </h3>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};




