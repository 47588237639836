import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem('is_authenticated');
    history.push('/login');
    window.location.reload();
  }, []);

  return null;
};


