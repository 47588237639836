import React, { useEffect, useState } from 'react';
import './css/Products.css';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Breadcrumbs, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Gallery } from "./Gallery";
export const ProductDetail = () => {
    const { code } = useParams();
    const url = `https://api.holvend.com/api/product/${code}`;
    const [product, setProduct] = useState({});
    const [productPhotos, setProductPhotos] = useState([])
    const [isLoading, setIsloading] = useState(true);
    const [howToUse, setHowToUse] = useState("")

    const getDetails = async () => {
        try {
            const response = await axios.get(url);
            setProduct(response.data.payload.datas);
            setProductPhotos(response.data.payload.datas.productPhotos);
            setIsloading(false);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const howToUseDesc = [
        {   //After Shave Splash
            // descriptionID: "Tuangkan secukupnya HOLVEND AFTER SHAVES di telapak tangan lalu usapkan ke bagian wajah atau leher yang terkena pisau cukur.",
            description: "Pour enough HOLVEND AFTER SHAVES into the palm of your hand then rub it onto the part of your face or neck where the razor touches it."
        },
        {   //Medium Weight Clay Paste
            // descriptionID: "Colek menggunakan jari secukupnya, usapkan ditelapak tangan secara merata lalu aplikasikan di rambut kalian. Ulangi kembali jika masih kurang atau ada bagian rambut yang belum teraplikasikan.",
            description: "Use just enough with your fingers, rub your palms evenly then apply it to your hair. Repeat again if it is still not enough or there are parts of the hair that have not been applied."
        },
        {   //Sea Salt Spray
            // descriptionID: "Kocok lalu semprot kan HOLVEND SURF TONIC pada rambut yang basah atau kering, atur sesuai selera dengan jari tangan atau sisir.",
            description: "Shake then spray HOLVEND SURF TONIC on wet or dry hair, adjust according to taste with your fingers or a comb."
        },
        {   //Your Summer Dream
            // description: "Kocok lalu semprotkan HOLVEND HAIR MIST pada rambut yang basah atau kering dengan jarak kurang lebih 10cm atur dengan jari tangan atau sisir.",
            description: "Shake then spray HOLVEND HAIR MIST on wet or dry hair at a distance of approximately 10cm using your fingers or a comb."
        },
    ]

    const checkDesc = () => {
        if (product.productCode === "shavesplash") {
            setHowToUse(howToUseDesc[0].np);
        } else if (product.productCode === "claypaste") {
            setHowToUse(howToUseDesc[1].description)
        } else if (product.productCode === "surftonic") {
            setHowToUse(howToUseDesc[2].description)
        } else if (product.productCode === "summerdream") {
            setHowToUse(howToUseDesc[3].description)
        } else {

        }
    };

    useEffect(() => {
        getDetails();
        checkDesc();
    }, [product.productCode]);

    if (isLoading) {
        return (
            <div>
                <h1>
                    LOADING...
                </h1>
            </div>
        )
    }

    return (
        <div className="container product-detail-container ">
            <div>
                <div className="product-breadcrumb" data-aos="fade-down">
                    <Breadcrumbs>
                        <Link underline="hover" color="#e0a80d" fontSize="20px" href="/home" >
                            <HomeIcon sx={{
                                mb: 0.4,
                                mr: 0.5
                            }} fontSize="inherit" />
                            Home
                        </Link>
                        <Link underline="hover" color="#e0a80d" fontSize="20px" href="/shop">
                            Shop
                        </Link>
                        {/* <Link underline="hover" color="#e0a80d" fontSize="20px" href={`/shop/${Category}`}> */}
                        <Link underline="always" color="#00000" fontSize="20px" href="#">
                            {product.product}
                        </Link>
                    </Breadcrumbs>
                </div>
                <Gallery imgs={productPhotos} />
            </div>
            <div className="product-detail-content" data-aos="fade-up">
                <h2 data-aos="fade-up" >{product.product}</h2>

                <strong data-aos="fade-up">Description: </strong> <br />
                <p data-aos="fade-up" >{product.description_en}</p>

                <strong data-aos="fade-up" >How to use: </strong> <br />
                {/* <p data-aos="fade-up">{product.how_to_use}</p> */}
                <p data-aos="fade-up" >{howToUse}</p>

                <p>Buy <strong>{product.product}</strong> at  </p>
                <div className='shop'>
                    {product.link_shopee && (
                        <a href={product.link_shopee}>
                            <img src={"/images/shopee.webp"} alt='' />
                        </a>
                    )}
                    {product.link_tokopedia && (
                        <a href={product.link_tokopedia}>
                            <img src={"/images/tokopedia.webp"} alt='' />
                        </a>
                    )}
                    {product.link_wa && (
                        <a href={product.link_wa}>
                            <img src={"/images/whatsapp.webp"} alt='' />
                        </a>
                    )}
                </div>
            </div >
        </div >
    );
};
